.main
    flex-grow: 1
    overflow: hidden

.b-welcome
    position: relative
    height: 100vh
    min-height: 700px
.b-welcome__image
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    &::after
        content: ''
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        background-color: rgba(#000, .35)
.b-welcome__info
    position: absolute
    left: 0
    right: 0
    top: 28%
    // transform: translateY(-50%)
.b-welcome__footer
    position: absolute
    bottom: 0
    left: 0
    right: 0

.down
    position: absolute
    bottom: 60px
    left: 20px
.svg-image-text-circle
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    animation: 15s rotate infinite normal linear
@keyframes rotate
    0%
        transform: rotate(0deg)
    to
        transform: rotate(-360deg)

.auth--decor
    position: absolute
    left: 0
    top: 0

.section-name-wrap
    position: absolute
    padding-top: 200px
    right: -150px
    top: 0
    &::after
        content: ''
        position: absolute
        left: 50%
        top: 0
        transform: translateX(-50%)
        width: 1px
        background-color: #000
        height: 170px
    &.t225
        top: 225px
.section-name
    writing-mode: vertical-rl
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
.swiper-projects-out
    max-width: 904px
    width: 100%
    position: relative
    margin-left: 0 !important
    margin-right: 0 !important
    .swiper-projects-area
        overflow: visible
.project-box-image
    box-shadow: 0px 2px 57px 9px rgba(48, 48, 48, 0.15)
.swiper-slide
    cursor: grab
    &:active
        cursor: grabbing
.swiper-projects-area
    // display: flex
    // margin-left: -75px
    // margin-right: -75px
    // .project-box-out
    //     width: 100%
    //     flex: 0 0 100%
// .project-box
//     &:hover
//         .project-box-name
//             opacity: 1
//             visibility: visible
// .project-box-name
//     opacity: 0
//     visibility: hidden
//     transition: all .25s ease-out
    span
        margin-right: 30px
.swiper--projects-pagination
    position: absolute
    left: 0
    bottom: 0
.project-pagi
    font-size: 21px
    line-height: 34px
    font-weight: bold
.svg-image-projects-decor
    position: absolute
    right: 0
    bottom: 0
.svg-image-hero-decor
    position: absolute
    bottom: -20%
    left: -10%
    width: 41.4%
.hamburger
    padding: 0
    font-size: 0
    vertical-align: top
    position: relative
    z-index: 100
.bg-video-wrap
    position: relative
    overflow: hidden
    width: 100%
    height: 100%

.splash-box
    position: fixed
    overflow: hidden
    left: 0
    top: 0
    bottom: 0
    right: 0
    z-index: 200
    background-color: #fff
    // background-image: url('../images/splash-bg.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    transition: all 1s
    opacity: 1
    // transform: translateY(0)
    visibility: visible
    &.is-active
        // transform: translateY(-100%)
        opacity: 0
        visibility: hidden
.sp-left-top
    position: absolute
    left: -1%
    top: -2%
    width: 50%
    svg
        max-width: 100%
        vertical-align: top
        display: block
        height: auto
.sp-bottom-right
    position: absolute
    bottom: 0
    right: 0
    width: 40%
    svg
        max-width: 100%
        vertical-align: top
        display: block
        height: auto
.sp-left-top-w
    position: absolute
    left: 0
    top: -2%
    width: 29%
    svg
        max-width: 100%
        vertical-align: top
        display: block
        height: auto
.sp-bottom-right-w
    position: absolute
    bottom: 0
    right: -13%
    width: 40%
    svg
        max-width: 100%
        vertical-align: top
        display: block
        height: auto
.splash-box-logo
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    max-width: 520px
    width: 62vh
    img
        width: 100%
        max-width: none
.splash-box__footer
    position: absolute
    left: 0
    right: 0
    text-align: center
    bottom: 0
.splash-box-info
    margin-bottom: 18px
.splash-box-line
    height: 170px
    width: 1px
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 50%)
    background-position: 0 -170px
    background-size: 100% 200%
    margin-left: auto
    margin-right: auto
    animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite
@keyframes scrolldown
    0%
        background-position: 0 -170px

    75%
        background-position: 0 0

    100%
        background-position: 0 170px

.headroom
    will-change: transform
    transition: transform 200ms linear

.headroom--pinned
    transform: translateY(0%)

.headroom--unpinned
    transform: translateY(-100%)

#trigger
    top: 0
    position: absolute
    left: 0
#hero-decor
    position: absolute
    left: -10%
    bottom: -1%
#hero-decor svg.fill
    g
    path
        &:nth-child(1)
            fill: #1E1E1E
            &:nth-child(2)
                fill: #787878
            &:nth-child(3)
                fill: #1E1E1E
#projects-decor svg.fill
    g
    path
        &:nth-child(1)
            fill: #1E1E1E
            &:nth-child(2)
                fill: #787878
            &:nth-child(3)
                fill: #1E1E1E
#author-decor svg.fill
    g
    path
        &:nth-child(1)
            fill: #1E1E1E
            &:nth-child(2)
                fill: #787878
            &:nth-child(3)
                fill: #1E1E1E
#projects-decor
    position: absolute
    right: 0
    top: 260px
    svg
        transform: rotateY(180deg)
#about
    overflow: hidden
#author-decor
    position: absolute
    left: -10%
    top: -12%
    width: 400px
    transform: rotateX(180deg)
.swiper-projects-next
    position: absolute
    background: rgba(0, 0, 0, 0.65)
    box-shadow: 0px 4px 29px 6px rgba(0, 0, 0, 0.22)
    border-radius: 2px
    height: 80px
    width: 80px
    position: absolute
    top: 50%
    transform: translateY(calc(-50% - 37px))
    right: -55px
    cursor: pointer
    z-index: 10
    .svg-image-slide-arrow-right
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
.swiper-projects-prev
    position: absolute
    background: rgba(0, 0, 0, 0.65)
    box-shadow: 0px 4px 29px 6px rgba(0, 0, 0, 0.22)
    border-radius: 2px
    height: 80px
    width: 80px
    position: absolute
    top: 50%
    transform: translateY(calc(-50% - 37px))
    left: -55px
    cursor: pointer
    z-index: 10
    .svg-image-slide-arrow-left
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
.swiper-button-disabled
    display: none
.text-white
    color: #fff
.z10
    position: relative
    z-index: 10
.bg--white
    background-color: #fff
.banner-image
    position: absolute
    top: 363px
    left: 50%
    transform: translateX(-50%)
    width: 1938px
    img
        width: 100%