.footer
    background-color: #333
    padding: 90px 0 40px
    color: #fff
    position: relative
    overflow: hidden
    // background-image: url('../images/footer-decor.svg')
    // background-repeat: no-repeat
    // background-position: right bottom
.copyright
    font-size: inherit
#footer-decor
    position: absolute
    right: -15%
    bottom: -1%
