@import "./normalize"
@import "../libs/bootstrap-grid/bootstrap-grid"
@import "../libs/hamburgers"
@import "../libs/swiper"
@import "../libs/aos"
@import "./vars"
@import "./mixins"
@import "./fonts"
@import "./sprite_generated"


// From libs:
//@import "../../libs/LIB_NAME/path/to/style.scss"
  
* 
    box-sizing: border-box
    // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()

body
    display: flex
    flex-direction: column
    background: #FFF
    font-family: "Aeonik", sans-serif
    font-size: 18px
    width: 100%
    height: 100%
    min-height: 100vh
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    min-width: 320px
    color: #000
a
    color: inherit
img
    vertical-align: top
    max-width: 100%
p
    margin-top: 0
h1,h2,h3,h4,h5,h6
    margin: 0
.cover__img
    height: 100%
    width: 100%
    max-width: 100%
    object-fit: cover
    vertical-align: top
.text-72
    font-size: 72px
    line-height: 94px
.w800
    max-width: 800px
    width: 100%
.pos--r
    position: relative


.text-48
    font-size: 48px
    line-height: 61px

.b50
    margin-top: 50px
.b-30
    margin-top: -30px
.b30
    margin-top: 30px

.form-styler
    font-size: inherit
    [type="text"],
    [type="tel"],
    [type="email"],
    [type="password"],
    textarea
        width: 100%
        max-width: 100%
        min-width: 100%
        vertical-align: top
        background-color: transparent
        border: none
        border-bottom: 1px solid #fff
        height: 40px
        color: inherit
        &::placeholder
            opacity: 1
            color: inherit
            font-size: inherit
    textarea
        height: 70px
        line-height: 28px
        resize: none
    [type="submit"],
    [type="reset"],
    [type="button"],
    button
        border: none
        background: linear-gradient(94.61deg, #2A84D1 0%, #40A7FF 100%)
        box-shadow: 0px 13px 24px 5px rgba(30, 30, 30, 0.7)
        border-radius: 2px
        line-height: 28px
        padding: 16px 38px
        color: inherit
        cursor: pointer
        font-weight: bold
        transition: all .25s ease-out
        &:hover
            box-shadow: none
.btn
    border: none
    box-shadow: 0px 13px 24px 5px rgba(193, 193, 193, 0.7)
    border-radius: 2px
    line-height: 28px
    padding: 16px 60px
    cursor: pointer
    font-weight: bold
    text-decoration: none
    display: inline-block
    vertical-align: top
    transition: all .25s ease-out
    &:hover
        box-shadow: none
.btn--blue
    background: linear-gradient(94.61deg, #2A84D1 0%, #40A7FF 100%)
    color: #fff
.b100
    margin-top: 100px
.bg--grey
    background-color: #F9F9F9
.resp__img
    max-width: 100%
    width: 100%
    display: block
.def-text
    line-height: 28px
    p
        margin-bottom: 20px
        &:last-of-type
            margin-bottom: 0
.pt190
    padding-top: 190px
.pb190
    padding-bottom: 190px
.pb252
    padding-bottom: 252px

.fix-offset
    display: flex
    flex-direction: column

.b90
    margin-top: 90px
.pl208
    padding-left: 208px

.b-40
    margin-top: -40px
.b40
    margin-top: 40px

.text-21
    font-size: 21px
    line-height: 34px

.pt155
    padding-top: 155px
.pb155
    padding-bottom: 155px

.w592
    max-width: 592px
    width: 100%
    margin-left: auto
    margin-right: auto
.text--center
    text-align: center
.text-48
    font-size: 48px
    line-height: 61px
.b20
    margin-top: 20px
.b-140
    margin-top: -140px
.b140
    margin-top: 140px
.mb20
    margin-bottom: 20px
.ov-box
    overflow: hidden

html
    &.is-active
        overflow: hidden
        body
            overflow: hidden
.w-700
    font-weight: 700
.tac
    text-align: center
.w800b
    max-width: 800px
    width: 100%
    margin-left: auto
    margin-right: auto
.ptb190
    padding-top: 190px
    padding-bottom: 190px
.text-18
    font-size: 18px
    line-height: 34px