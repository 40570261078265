@use 'sass:math';

%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-arrow {
	@extend %svg-common;
	background-position: 79.3606281547953% 46.878251821019774%;
	background-size: math.div(1922, 139)*100% auto;
	width: 139px;

}
@mixin svg-pos-image-arrow {
	background-position: 79.3606281547953% 46.878251821019774%;
}
@mixin svg-image-arrow-hover {
	.svg-image-arrow {
		@include svg-pos-image-arrow-hover
	}
}

.svg-image-arrow:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(140, 139)*100%;
}

.svg-image-arrow-dims {
	width: 139px;
	height: 140px;
}

.svg-image-author-decor {
	@extend %svg-common;
	background-position: 0 0;
	background-size: math.div(1922, 1922)*100% auto;
	width: 1922px;

}
@mixin svg-pos-image-author-decor {
	background-position: 0 0;
}
@mixin svg-image-author-decor-hover {
	.svg-image-author-decor {
		@include svg-pos-image-author-decor-hover
	}
}

.svg-image-author-decor:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(901, 1922)*100%;
}

.svg-image-author-decor-dims {
	width: 1922px;
	height: 901px;
}

.svg-image-hero-decor {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: math.div(1922, 917)*100% auto;
	width: 917px;

}
@mixin svg-pos-image-hero-decor {
	background-position: 0 100%;
}
@mixin svg-image-hero-decor-hover {
	.svg-image-hero-decor {
		@include svg-pos-image-hero-decor-hover
	}
}

.svg-image-hero-decor:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(1161, 917)*100%;
}

.svg-image-hero-decor-dims {
	width: 917px;
	height: 1161px;
}

.svg-image-projects-decor {
	@extend %svg-common;
	background-position: 64.39606741573034% 77.47205503009458%;
	background-size: math.div(1922, 498)*100% auto;
	width: 498px;

}
@mixin svg-pos-image-projects-decor {
	background-position: 64.39606741573034% 77.47205503009458%;
}
@mixin svg-image-projects-decor-hover {
	.svg-image-projects-decor {
		@include svg-pos-image-projects-decor-hover
	}
}

.svg-image-projects-decor:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(899, 498)*100%;
}

.svg-image-projects-decor-dims {
	width: 498px;
	height: 899px;
}

.svg-image-slide-arrow-left {
	@extend %svg-common;
	background-position: 89.38753959873284% 44.08023483365949%;
	background-size: math.div(1922, 28)*100% auto;
	width: 28px;

}
@mixin svg-pos-image-slide-arrow-left {
	background-position: 89.38753959873284% 44.08023483365949%;
}
@mixin svg-image-slide-arrow-left-hover {
	.svg-image-slide-arrow-left {
		@include svg-pos-image-slide-arrow-left-hover
	}
}

.svg-image-slide-arrow-left:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(18, 28)*100%;
}

.svg-image-slide-arrow-left-dims {
	width: 28px;
	height: 18px;
}

.svg-image-slide-arrow-right {
	@extend %svg-common;
	background-position: 90.86589229144667% 44.08023483365949%;
	background-size: math.div(1922, 28)*100% auto;
	width: 28px;

}
@mixin svg-pos-image-slide-arrow-right {
	background-position: 90.86589229144667% 44.08023483365949%;
}
@mixin svg-image-slide-arrow-right-hover {
	.svg-image-slide-arrow-right {
		@include svg-pos-image-slide-arrow-right-hover
	}
}

.svg-image-slide-arrow-right:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(18, 28)*100%;
}

.svg-image-slide-arrow-right-dims {
	width: 28px;
	height: 18px;
}

.svg-image-text-circle {
	@extend %svg-common;
	background-position: 87.15647784632641% 46.878251821019774%;
	background-size: math.div(1922, 139)*100% auto;
	width: 139px;

}
@mixin svg-pos-image-text-circle {
	background-position: 87.15647784632641% 46.878251821019774%;
}
@mixin svg-image-text-circle-hover {
	.svg-image-text-circle {
		@include svg-pos-image-text-circle-hover
	}
}

.svg-image-text-circle:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(140, 139)*100%;
}

.svg-image-text-circle-dims {
	width: 139px;
	height: 140px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }