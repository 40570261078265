@import "vars"

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1500px)
	#footer-decor
		width: 60%
	.svg-image-author-decor
		width: 100vw
	.svg-image-hero-decor
		width: 65vw
@media only screen and (max-width : 1199px)
	/**/
	.ptb190
		padding-top: 80px
		padding-bottom: 80px
	.banner-image
		top: 200px
	.text-72
		font-size: 58px
		line-height: 1.2
	.pt190
		padding-top: 80px
	.pb190
		padding-bottom: 80px
	.pt155
		padding-top: 80px
	.pb155
		padding-bottom: 80px
	#projects-decor,
	#author-decor,
	#hero-decor
		display: none
	.svg-image-hero-decor
		width: 60vw
/* Medium Devices, Desktops */
@media only screen and (max-width : 991px)
	/**/
	body
		font-size: 16px
	.text-72
		font-size: 40px
	.text-48
		font-size: 38px
		line-height: 1.2
	.b-welcome
		height: 600px
		min-height: auto
	.b-welcome__footer
		display: none
	.b-welcome__info
		top: 50%
		transform: translateY(-50%)
	.rellax
		transform: none !important
		visibility: visible !important
		opacity: 1 !important
	.svg-image-hero-decor
		width: 400px
	.svg-image-projects-decor
		width: 200px
	.pl208
		padding-left: 0
	.b90
		margin-top: 45px
	.svg-image-author-decor
		width: 1000px
	.pb252
		padding-bottom: 80px
	.splash-box-logo
		width: auto
	.project-box-name
		opacity: 1 !important
		visibility: visible !important
	#footer-decor
		width: 60%
	.svg-image-author-decor
		width: 95vw
/* Small Devices, Tablets */
@media only screen and (max-width : 767px)
	/**/
	.text-18
		line-height: 30px
	.w800b
		.b30
			margin-top: 15px
	.banner-image
		top: 300px
		width: 1000px
	.svg-image-hero-decor,
	#footer-decor
		display: none
	.logo
		width: 120px
	.header
		padding-top: 20px
	.nav

		position: fixed
		left: 0
		right: 0
		top: 0
		bottom: 0
		height: 100vh
		background-color: #fff
		display: none
		overscroll-behavior-y: contain
		.nav__list
			display: flex
			flex-direction: column
			justify-content: center
			text-align: center
			margin: 0
			height: 100%
			font-size: 30px
			li
				padding: 0
				margin-bottom: 30px
				&:last-child
					margin-bottom: 0
	.text-48
		font-size: 34px
	.text-72
		font-size: 32px
	.b-welcome
		height: 400px
	.text-21,
	.project-pagi
		font-size: 18px
		line-height: 1.2
	.project-box-name
		padding-left: 0
		opacity: 1 !important
		visibility: visible !important
		text-align: center
		margin-top: 10px
	.swiper--projects-pagination
		position: relative
		text-align: center
		margin-top: 10px
	.about-cont
		padding-top: 0
		padding-bottom: 45px
	.b50
		margin-top: 25px
	.svg-image-hero-decor
		width: 200px
		left: 0
		bottom: 0
		opacity: 0.5
	.svg-image-projects-decor
		width: 205px
		opacity: 0.5
		right: -48px
		bottom: 20px
	.footer
		padding-top: 45px
		padding-bottom: 20px
		background-image: none
	.copyright
		text-align: center
		font-size: 14px
		display: block
	.form-styler [type=button], .form-styler [type=reset], .form-styler [type=submit], .form-styler button
		width: 100%
	.swiper-projects-area
		margin-left: -20px
		margin-right: -20px
	.swiper-projects-area .project-box-out
		padding-left: 20px
		padding-right: 20px
	.aos-animate
		opacity: 1 !important
	.swiper-projects-out
		margin-left: -20px !important
		margin-right: -20px !important
		// padding-right: 100px
		padding-left: 80px
		padding-right: 80px
		width: auto !important
	.swiper-projects-area .project-box-out
		padding-left: 0
		padding-right: 0
	.mb-tac
		text-align: center
	.b-welcome__info
		position: relative
		padding-top: 40px
		padding-bottom: 40px
		transform: none
		top: auto
		bottom: auto
		.text-white
			color: #000
	.b-welcome
		height: auto
	.b-welcome__image
		height: 400px
	.mbt0
		padding-top: 0
	.main
		padding-top: 87px
/* Small Devices, Tablets */
@media only screen and (max-height : 576px)
	/**/
	.splash-box__footer
		bottom: -100px
@media only screen and (max-width : 576px)
	/**/

/* Extra Small Devices, Phones */
@media only screen and (max-width : 479px)
	/**/


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px)
	/**/


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px)
	/**/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px)
	/**/


/* Small Devices, Tablets */
@media only screen and (min-width : 768px)
	/**/
	.hamburger
		display: none
	.nav
		display: block !important
/* Medium Devices, Desktops */
@media only screen and (min-width : 992px)
	/**/


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px)
	/**/
	.w592
		width: 592px
	.w486
		width: 486px
	.w488
		width: 488px
	.w800
		width: 770px
	.w384
		max-width: 426px
		width: 100%
	.mr88
		margin-right: -88px
