.header
    padding-top: 40px
    padding-bottom: 20px
    position: fixed
    left: 0
    right: 0
    top: 0
    z-index: 10
    background-color: #fff
    transition: all .4s
    &.headroom--not-top
        padding-top: 10px
        padding-bottom: 10px
        .row
            align-items: center !important
        .logo
            img
                width: 120px
.animated
    -webkit-animation-duration: .5s
    -moz-animation-duration: .5s
    -o-animation-duration: .5s
    animation-duration: .5s
    -webkit-animation-fill-mode: both
    -moz-animation-fill-mode: both
    -o-animation-fill-mode: both
    animation-fill-mode: both
    will-change: transform,opacity
.animated.slideUp
    animation-name: slideUp
.animated.slideDown
    animation-name: slideDown
@keyframes slideUp
    0%
        transform: translateY(0)

    100%
        transform: translateY(-100%)
@keyframes slideDown
    0%
        transform: translateY(-100%)

    100%
        transform: translateY(0)
.logo
    img
        transition: all .4s
        width: 183px
.nav__list
    padding: 0
    margin: 0
    list-style-type: none
    display: flex
    font-size: 18px
    line-height: 28px
    margin-left: -15px
    margin-right: -15px
    &>li
        padding-left: 15px
        padding-right: 15px
        &.current-menu-item
            &>a
                &::after
                    transform: scale(1,1)
    a
        text-decoration: none
        position: relative
        &::after
            content: ''
            position: absolute
            left: 0
            right: 0
            bottom: -1px
            height: 1px
            background-color: #000
            transition: all .25s ease-out
            transform: scale(0,1)
            transform-origin: left
        &:hover
            &::after
                transform: scale(1,1)
